<template>
  <v-app v-if="user">
    <dashboard-core-app-bar />
    <dashboard-core-drawer />
    <dashboard-core-view />
    <snackbar />
  </v-app>
</template>

<script>
import Snackbar from '@/views/dashboard/components/Snackbar'
export default {
  name: 'DashboardIndex',

  components: {
    Snackbar,
    DashboardCoreAppBar: () => import('./dashboardComponents/core/AppBar'),
    DashboardCoreDrawer: () => import('./dashboardComponents/core/Drawer'),
    DashboardCoreView: () => import('./dashboardComponents/core/View'),
  },
  data: () => ({
    expandOnHover: false,
    showSnackbar: true,
  }),
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
    snackbarMessage () {
      return this.$store.getters.getUserLogged
    },

  },
}
</script>
